
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #b5b67f; /* Change the background color to #b5b67f */
  text-align: center; /* Add this line to center the content horizontally */
}

.grid-row {
  display: flex; /* Make this a flex container */
  justify-content: center; /* Center the items on the row */
  padding: 10px 0;
  width: 100%;
}

.grid-item {
  flex: 1 0 auto; /* Add this line */
  height: 125px;
  width: 130px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 0 10px;
  font-size: 1.2em;
  text-align: center; /* Center the text within the item */
  display: flex; /* Add this line */
  justify-content: center; /* Add this line */
  align-items: center;
  border-radius: 15px;
}

.grid-item.selected {
  background-color: #7c4141ce; /* Change this to the color you want */
}

.bingo-message {
  font-size: 2em; /* Make the text bigger */
  text-align: center; /* Center the text horizontally */
  width: 100%; /* Make the message take up the full width of its container */
  display: flex; /* Add this line */
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
  height: 100vh; /* Add this line */
  position: absolute; /* Add this line */
  top: 0; /* Add this line */
  left: 0; /* Add this line */
  background: rgba(0, 0, 0, 0.7); /* Add this line */
  color: #fff; /* Add this line */
}

@media (max-width: 600px) {
  
  .grid-item {
    margin: flex;
    width: 70px; /* Decrease the width for smaller screens */
    height: 70px; /* Decrease the height for smaller screens */
    font-size: 0.55em; /* Add this line to match the font size with the box */
    display: flex; /* Add this line */
    justify-content: center; /* Add this line */
    align-items: center; /* Add this line */
  }
  

  .bingo-message {
    font-size: 1.2em; /* Add this line to match the font size with the box */
    display: flex; /* Add this line */
    justify-content: center; /* Add this line */
    align-items: center; /* Add this line */
  }
}